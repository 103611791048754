@charset "UTF-8";
@font-face {
  font-family: Circe-Bold;
  src: url("../fonts/Circe-Bold.eot");
  src: url("../fonts/Circe-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Bold.woff") format("woff"), url("../fonts/Circe-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: Circe-ExtraBold;
  src: url("../fonts/Circe-ExtraBold.eot");
  src: url("../fonts/Circe-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-ExtraBold.woff") format("woff"), url("../fonts/Circe-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: Circe-Regular;
  src: url("../fonts/Circe-Regular.eot");
  src: url("../fonts/Circe-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Circe-Regular.woff") format("woff"), url("../fonts/Circe-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  font-size: 18px;
  font-family: Circe-Regular, sans-serif; }

.container {
  max-width: 1120px;
  margin: 0 auto;
  *zoom: 1; }
  .container:after {
    content: " ";
    display: table;
    clear: both; }
  @media only screen and (min-width: 320px) {
    .container {
      width: 90%; } }
  @media only screen and (min-width: 768px) {
    .container {
      width: 80%; } }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  color: #0B80E0;
  text-decoration: underline; }

.header__top {
  background-color: #fff;
  padding: 35px 0; }

.header__logo {
  font-family: Circe-Bold;
  font-size: .7em;
  text-transform: uppercase;
  width: 90%;
  float: left;
  color: #0E182D;
  padding-left: 20px; }

.header__search {
  width: 33px;
  height: 34px;
  background-image: url(../img/search.png);
  background-size: contain;
  cursor: pointer;
  float: right; }

.header__menu {
  background-color: #0E182D;
  color: #fff;
  box-sizing: border-box;
  position: relative; }

.header__menu-item {
  width: 30%;
  display: inline-block;
  transition: .3s; }
  @media only screen and (min-width: 320px) {
    .header__menu-item {
      width: 100%; } }
  @media only screen and (min-width: 768px) {
    .header__menu-item {
      width: 47%; } }
  @media only screen and (min-width: 1024px) {
    .header__menu-item {
      width: 30%; } }
  .header__menu-item:last-child span:after {
    content: '';
    display: block;
    width: 1px;
    height: 40px;
    position: absolute;
    top: 20px;
    left: -2px;
    background-color: #fff; }
    @media only screen and (min-width: 320px) {
      .header__menu-item:last-child span:after {
        display: none; } }
    @media only screen and (min-width: 768px) {
      .header__menu-item:last-child span:after {
        display: block; } }
  @media only screen and (max-width: 767px) {
    .header__menu-item > ul.active {
      display: block;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      position: fixed;
      top: 0;
      overflow-y: auto; } }
  @media only screen and (min-width: 768px) {
    .header__menu-item:hover {
      background-color: #0d2d51; } }
  @media only screen and (min-width: 768px) {
    .header__menu-item:hover > ul {
      display: block; } }
  .header__menu-item span {
    line-height: 1em;
    font-family: Circe-ExtraBold;
    display: block;
    cursor: pointer;
    box-sizing: border-box;
    height: 80px;
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 20px;
    position: relative; }
    @media only screen and (min-width: 1024px) {
      .header__menu-item span {
        font-size: 1.1em; } }
    @media only screen and (min-width: 1366px) {
      .header__menu-item span {
        font-size: 1.333em; } }
  .header__menu-item a {
    color: #fff;
    text-decoration: none; }

.header__home {
  margin-left: -64px;
  width: 40px;
  padding-right: 10px;
  padding-left: 10px;
  height: 38px;
  background-image: url(../img/home.png);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer; }
  @media only screen and (min-width: 320px) {
    .header__home {
      display: none; } }
  @media only screen and (min-width: 768px) {
    .header__home {
      display: inline-block; } }

.header__submenu-col {
  width: 50%;
  display: inline-block;
  vertical-align: top; }
  @media only screen and (min-width: 320px) {
    .header__submenu-col {
      font-size: .8rem; } }
  @media only screen and (min-width: 768px) {
    .header__submenu-col {
      font-size: 1.111rem; } }

.header__submenu {
  padding-bottom: 20px;
  display: none;
  min-height: 300px;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 80px;
  left: 0;
  font-size: 0;
  z-index: 999; }
  @media only screen and (min-width: 320px) {
    .header__submenu {
      padding-top: 60px; } }
  @media only screen and (min-width: 768px) {
    .header__submenu {
      padding-top: 20px; } }

.header__submenu-item {
  font-family: Circe-Regular;
  box-sizing: border-box;
  transition: .3s; }
  @media only screen and (min-width: 320px) {
    .header__submenu-item {
      padding-left: 20px; } }
  @media only screen and (min-width: 768px) {
    .header__submenu-item {
      padding-left: 5px; } }
  .header__submenu-item:hover {
    background-color: rgba(13, 45, 81, 0.8); }
  .header__submenu-item a {
    color: #d0d1d3; }
  .header__submenu-item_title {
    margin-top: 10px; }
    .header__submenu-item_title a {
      color: #fff; }

.header__submenu-close {
  width: 50px;
  height: 50px;
  background-image: url(../img/acordeon__close.png);
  background-size: contain;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%); }
  @media only screen and (min-width: 768px) {
    .header__submenu-close {
      display: none; } }

.footer {
  background-color: #2A2A2A;
  padding: 30px 0; }
  .footer__top {
    font-size: .777em;
    color: #fff; }
    @media only screen and (min-width: 1024px) {
      .footer__top {
        width: 70%; } }
    @media only screen and (min-width: 1366px) {
      .footer__top {
        width: 55%; } }
    .footer__top a {
      font-size: .666em;
      text-transform: uppercase; }
  .footer__hr {
    width: 45px;
    height: 2px;
    background-color: #FF6863;
    margin-top: 10px;
    margin-bottom: 10px; }
  .footer__link {
    font-size: .666em;
    color: #fff; }

.b-title {
  height: 600px;
  background-size: cover;
  background-position: 50%;
  position: relative; }
  .b-title_main {
    background-image: url(../img/bg-1.jpg); }
  .b-title_conventions {
    background-image: url(../img/bg-2.jpg); }
  .b-title__title {
    font-family: Circe-Bold;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1em; }
    @media only screen and (min-width: 768px) {
      .b-title__title {
        width: 65%; } }
    @media only screen and (min-width: 320px) {
      .b-title__title_m {
        font-size: 1em;
        bottom: 0; } }
    @media only screen and (min-width: 768px) {
      .b-title__title_m {
        font-size: 2em;
        bottom: 100px; } }
    @media only screen and (min-width: 1366px) {
      .b-title__title_m {
        font-size: 3em; } }
    @media only screen and (min-width: 1600px) {
      .b-title__title_m {
        font-size: 5em; } }

.main-title {
  position: absolute;
  right: 0;
  background-color: rgba(11, 128, 224, 0.8);
  color: #FFF;
  padding-left: 30px;
  padding-right: 40px;
  box-sizing: border-box; }
  @media only screen and (min-width: 320px) {
    .main-title {
      height: 50%;
      bottom: 0;
      padding-top: 20px; } }
  @media only screen and (min-width: 1024px) {
    .main-title {
      padding-left: 20px;
      padding-right: 20px;
      height: calc(100% + 80px);
      width: 35%;
      top: -80px;
      padding-top: 200px; } }
  @media only screen and (min-width: 1366px) {
    .main-title {
      padding-left: 30px;
      padding-right: 40px; } }
  .main-title__bold {
    font-family: Circe-Bold;
    font-size: 2em;
    margin-bottom: 40px; }
  .main-title__descr {
    font-size: 1.333em; }
    @media only screen and (min-width: 1024px) {
      .main-title__descr {
        font-size: 1.1em; } }
    @media only screen and (min-width: 1366px) {
      .main-title__descr {
        font-size: 1.333em; } }

.news {
  padding: 170px 10%;
  *zoom: 1; }
  .news:after {
    content: " ";
    display: table;
    clear: both; }
  @media only screen and (min-width: 768px) {
    .news {
      padding: 170px 0; } }
  @media only screen and (min-width: 1024px) {
    .news {
      padding: 170px 10%; } }
  .news__item {
    background-color: #e6e7ea;
    width: 280px;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    cursor: pointer; }
    .news__item_left {
      float: left; }
    .news__item_right {
      float: right; }
  .news__img {
    height: 55px; }
  .news__title {
    font-family: Circe-ExtraBold;
    font-size: 1.333em;
    margin-top: 25px;
    text-transform: uppercase; }

.section {
  font-size: 1.111em;
  font-family: Circe-Regular;
  padding: 90px 0; }
  .section__text {
    margin: 0; }
  .section ul {
    line-height: 1em; }
    .section ul li {
      position: relative;
      padding-left: 20px; }
      .section ul li:before {
        content: '●';
        font-size: .6em;
        position: absolute;
        left: 0; }
  .section__text_border {
    font-family: Circe-Bold;
    line-height: 1.1em;
    color: #0E182D;
    margin: 50px 0; }
    @media only screen and (min-width: 320px) {
      .section__text_border {
        font-size: 1.5em;
        padding-left: 20px;
        border-left: 5px solid #0E182D; } }
    @media only screen and (min-width: 768px) {
      .section__text_border {
        font-size: 2em;
        padding-left: 35px;
        border-left: 10px solid #0E182D; } }

.links {
  *zoom: 1; }
  .links:after {
    content: " ";
    display: table;
    clear: both; }
  .links__item {
    font-size: .777em;
    padding-left: 40px;
    margin-top: 20px;
    background-image: url(../img/link.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    box-sizing: border-box;
    min-height: 40px;
    text-decoration: none; }
    @media only screen and (min-width: 320px) {
      .links__item {
        lost-column: 1; } }
    @media only screen and (min-width: 768px) {
      .links__item {
        lost-column: 1/2; } }
    @media only screen and (min-width: 1366px) {
      .links__item {
        lost-column: 1/4; } }
    .links__item span {
      display: block;
      width: 100%;
      height: 100%;
      color: #0E182D;
      font-family: Circe-Bold; }

.acordeon {
  margin-bottom: 55px;
  margin-top: 55px;
  position: relative;
  *zoom: 1; }
  .acordeon:after {
    content: " ";
    display: table;
    clear: both; }
  .acordeon__container {
    width: 80%;
    float: left; }
  .acordeon__content-container {
    color: #7a7a8d; }
    @media only screen and (min-width: 320px) {
      .acordeon__content-container {
        font-size: .8em; } }
    @media only screen and (min-width: 768px) {
      .acordeon__content-container {
        font-size: 1em; } }
  .acordeon__title {
    line-height: 1.1em;
    font-family: Circe-ExtraBold;
    color: #0E182D;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 40px; }
    @media only screen and (min-width: 320px) {
      .acordeon__title {
        font-size: 1.2em;
        padding-left: 40px; } }
    @media only screen and (min-width: 768px) {
      .acordeon__title {
        font-size: 2.5em;
        padding-left: 80px; } }
    .acordeon__title:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (min-width: 320px) {
        .acordeon__title:before {
          width: 25px;
          border: 2px solid #0E182D; } }
      @media only screen and (min-width: 768px) {
        .acordeon__title:before {
          width: 50px;
          border: 4px solid #0E182D; } }
  .acordeon__btn {
    display: block;
    text-align: center;
    background-color: #FF6863;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: .5s; }
    @media only screen and (min-width: 320px) {
      .acordeon__btn {
        width: 40px;
        min-height: 80px; } }
    @media only screen and (min-width: 768px) {
      .acordeon__btn {
        width: 65px;
        min-height: 128px; } }
    .acordeon__btn span {
      display: block;
      position: absolute;
      transition: .5s; }
      @media only screen and (min-width: 320px) {
        .acordeon__btn span {
          width: 40px;
          height: 40px;
          top: 20px; } }
      @media only screen and (min-width: 768px) {
        .acordeon__btn span {
          width: 65px;
          height: 65px;
          top: 30px; } }
      .acordeon__btn span:after {
        content: '+';
        color: #fff;
        font-family: Circe-ExtraBold;
        font-size: 4em;
        text-align: center;
        display: block;
        overflow: hidden; }
        @media only screen and (min-width: 320px) {
          .acordeon__btn span:after {
            font-size: 3em;
            line-height: 50px; } }
        @media only screen and (min-width: 768px) {
          .acordeon__btn span:after {
            font-size: 4em;
            line-height: 80px; } }
    .acordeon__btn.active {
      background-color: #0E182D;
      height: 100%; }
      .acordeon__btn.active span {
        transform: rotate(135deg); }
  .acordeon__content {
    display: none; }

.search-container {
  background-color: #f1f1f1; }
  @media only screen and (min-width: 320px) {
    .search-container {
      padding-top: 100px;
      padding-bottom: 50px; } }
  @media only screen and (min-width: 768px) {
    .search-container {
      padding-top: 130px;
      padding-bottom: 80px; } }
  .search-container__title {
    font-family: Circe-Bold;
    color: #0E182D; }
    @media only screen and (min-width: 320px) {
      .search-container__title {
        font-size: 2em; } }
    @media only screen and (min-width: 768px) {
      .search-container__title {
        font-size: 3.6em; } }

.search {
  position: relative; }
  @media only screen and (min-width: 320px) {
    .search {
      width: 70%;
      padding-right: 30%; } }
  @media only screen and (min-width: 768px) {
    .search {
      width: 80%;
      padding-right: 20%; } }
  .search__input {
    border: none;
    border-radius: 0;
    height: 60px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 1.2em;
    padding: 20px;
    outline: none; }
  .search__btn {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    background-color: #FF6863;
    text-transform: uppercase;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color .3s; }
    @media only screen and (min-width: 320px) {
      .search__btn {
        width: 30%; } }
    @media only screen and (min-width: 768px) {
      .search__btn {
        width: 20%; } }
    .search__btn:hover {
      background-color: #ED3E3E; }
  .search__result-stats {
    font-family: Circe-ExtraBold;
    margin-top: 50px; }
    @media only screen and (min-width: 320px) {
      .search__result-stats {
        font-size: .9em; } }
    @media only screen and (min-width: 768px) {
      .search__result-stats {
        font-size: 1em; } }
  .search__result-value {
    color: #0b80e0; }

.result-items {
  padding-top: 60px;
  padding-bottom: 120px; }
  @media only screen and (min-width: 768px) {
    .result-items {
      width: 70%; } }

.result-item {
  margin-top: 30px; }
  .result-item__title {
    color: #7a7a8b;
    font-family: Circe-ExtraBold; }
  .result-item__content {
    color: #7a7a8b; }
    .result-item__content span {
      color: #0b80e0; }

.btn-back {
  display: block;
  width: 200px;
  text-decoration: none;
  font-family: Circe-ExtraBold;
  text-transform: uppercase;
  line-height: 48px;
  text-align: right;
  padding: 0px 50px;
  box-sizing: border-box;
  color: #000;
  margin-top: 50px;
  border: 1px solid #000;
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  background-position: 35px 50%;
  transition: .3s; }
  .btn-back:hover {
    background-color: #c0c0c0; }
