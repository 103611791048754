.footer {
  background-color: $grey;
  padding: 30px 0;

  &__top {
    font-size: .777em;
    color: #fff;

    @include rm(1024) {
      width: 70%; }
    @include rm(1366) {
      width: 55%; }

    a {
      font-size: .666em;
      text-transform: uppercase; } }

  &__hr {
    width: 45px;
    height: 2px;
    background-color: $red;
    margin-top: 10px;
    margin-bottom: 10px; }

  &__link {
    font-size: .666em;
    color: #fff; } }
