.b-title {
  height: 600px;
  background-size: cover;
  background-position: 50%;
  position: relative;

  &_main {
    background-image: url(../img/bg-1.jpg); }

  &_conventions {
    background-image: url(../img/bg-2.jpg); }

  &__title {
    font-family: $bold;
    position: absolute;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1em;

    @include rm(768) {
      width: 65%; }

    &_m {
      @include rm(320) {
        font-size: 1em;
        bottom: 0; }
      @include rm(768) {
        font-size: 2em;
        bottom: 100px; }
      @include rm(1366) {
        font-size: 3em; }
      @include rm(1600) {
        font-size: 5em; } } } }

.main-title {
  position: absolute;
  right: 0;
  background-color: rgba(11,128,224,.8);
  color: #FFF;
  padding-left: 30px;
  padding-right: 40px;
  box-sizing: border-box;


  @include rm(320) {
    height: 50%;
    bottom: 0;
    padding-top: 20px; }

  @include rm(1024) {
    padding-left: 20px;
    padding-right: 20px;
    height: calc(100% + 80px);
    width: 35%;
    top: -80px;
    padding-top: 200px; }
  @include rm(1366) {
    padding-left: 30px;
    padding-right: 40px; }

  &__bold {
    font-family: $bold;
    font-size: 2em;
    margin-bottom: 40px; }

  &__descr {
    font-size: 1.333em;

    @include rm(1024) {
      font-size: 1.1em; }
    @include rm(1366) {
      font-size: 1.333em; } } }
