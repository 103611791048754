.search-container {
  background-color: #f1f1f1;

  @include rm(320) {
    padding-top: 100px;
    padding-bottom: 50px; }
  @include rm(768) {
    padding-top: 130px;
    padding-bottom: 80px; }

  &__title {
    font-family: $bold;
    color: $darkBlue;
    @include rm(320) {
      font-size: 2em; }
    @include rm(768) {
      font-size: 3.6em; } } }

.search {
  position: relative;
  @include rm(320) {
    width: 70%;
    padding-right: 30%; }
  @include rm(768) {
    width: 80%;
    padding-right: 20%; }

  &__input {
    border: none;
    border-radius: 0;
    height: 60px;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-size: 1.2em;
    padding: 20px;
    outline: none; }

  &__btn {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 60px;
    background-color: $red;
    text-transform: uppercase;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color .3s;
    @include rm(320) {
      width: 30%; }
    @include rm(768) {
      width: 20%; }

    &:hover {
      background-color: $redHover; } }


  &__result-stats {
    font-family: $extraBold;
    margin-top: 50px;
    @include rm(320) {
      font-size: .9em; }
    @include rm(768) {
      font-size: 1em; } }

  &__result-value {
    color: #0b80e0; } }

.result-items {
  padding-top: 60px;
  padding-bottom: 120px;
  @include rm(768) {
    width: 70%; } }

.result-item {
  margin-top: 30px;

  &__title {
    color: #7a7a8b;
    font-family: $extraBold; }

  &__content {
    color: #7a7a8b;

    span {
      color: #0b80e0; } } }

.btn-back {
  display: block;
  width: 200px;
  text-decoration: none;
  font-family: $extraBold;
  text-transform: uppercase;
  line-height: 48px;
  text-align: right;
  padding: 0px 50px;
  box-sizing: border-box;
  color: #000;
  margin-top: 50px;
  border: 1px solid #000;
  background-image: url(../img/back.png);
  background-repeat: no-repeat;
  background-position: 35px 50%;
  transition: .3s;

  &:hover {
    background-color: #c0c0c0; } }
