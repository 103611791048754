.acordeon {
  margin-bottom: 55px;
  margin-top: 55px;
  position: relative;
  @include clr;

  &__container {
    width: 80%;
    float: left; }


  &__content-container {
    color: #7a7a8d;

    @include rm(320) {
      font-size: .8em; }
    @include rm(768) {
      font-size: 1em; } }

  &__title {
    line-height: 1.1em;
    font-family: $extraBold;
    color: $darkBlue;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 40px;

    @include rm(320) {
      font-size: 1.2em;
      padding-left: 40px; }
    @include rm(768) {
      font-size: 2.5em;
      padding-left: 80px; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top:  50%;
      transform: translateY(-50%);

      @include rm(320) {
        width: 25px;
        border: 2px solid $darkBlue; }
      @include rm(768) {
        width: 50px;
        border: 4px solid $darkBlue; } } }

  &__btn {

    display: block;
    text-align: center;
    background-color: $red;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: .5s;

    @include rm(320) {
      width: 40px;
      min-height: 80px; }
    @include rm(768) {
      width: 65px;
      min-height: 128px; }

    span {
      display: block;
      position: absolute;
      transition: .5s;

      @include rm(320) {
        width: 40px;
        height: 40px;
        top: 20px; }
      @include rm(768) {
        width: 65px;
        height: 65px;
        top: 30px; }

      &:after {
        content: '+';
        color: #fff;
        font-family: $extraBold;
        font-size: 4em;
        text-align: center;
        display: block;
        overflow: hidden;

        @include rm(320) {
          font-size: 3em;
          line-height: 50px; }
        @include rm(768) {
          font-size: 4em;
          line-height: 80px; } } }

    &.active {
      background-color: $darkBlue;
      height: 100%;
      span {
        transform: rotate(135deg); } } }


  &__content {
    display: none; } }
