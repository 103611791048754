.header {

  &__top {
    background-color: #fff;
    padding: 35px 0; }

  &__logo {
    font-family: $bold;
    font-size: .7em;
    text-transform: uppercase;
    width: 90%;
    float: left;
    color: $darkBlue;
    padding-left: 20px; }

  &__search {
    width: 33px;
    height: 34px;
    background-image: url(../img/search.png);
    background-size: contain;
    cursor: pointer;
    float: right; }

  &__menu {
    background-color: $darkBlue;
    color: #fff;
    box-sizing: border-box;
    position: relative; }

  &__menu-item {
    width: 30%;
    display: inline-block;
    transition: .3s;
    @include rm(320) {
      width: 100%; }
    @include rm(768) {
      width: 47%; }
    @include rm(1024) {
      width: 30%; }

    &:last-child span:after {
      content: '';
      display: block;
      width: 1px;
      height: 40px;
      position: absolute;
      top: 20px;
      left: -2px;
      background-color: #fff;
      @include rm(320) {
        display: none; }
      @include rm(768) {
        display: block; } }

    & > ul.active {
      @include r(767) {
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        overflow-y: auto; } }

    &:hover {
      @include rm(768) {
        background-color: $lightBlue; }

      & > ul {
        @include rm(768) {
          display: block; } } }

    span {
      line-height: 1em;
      font-family: $extraBold;
      display: block;
      cursor: pointer;
      box-sizing: border-box;
      height: 80px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
      position: relative;

      @include rm(1024) {
        font-size: 1.1em; }
      @include rm(1366) {
        font-size: 1.333em; } }

    a {
      color: #fff;
      text-decoration: none; } }

  &__home {
    margin-left: -64px;
    width: 40px;
    padding-right: 10px;
    padding-left: 10px;
    height: 38px;
    background-image: url(../img/home.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;

    @include rm(320) {
      display: none; }
    @include rm(768) {
      display: inline-block; } }

  &__submenu-col {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    @include rm(320) {
      font-size: .8rem; }
    @include rm(768) {
      font-size: 1.111rem; } }


  &__submenu {
    padding-bottom: 20px;
    display: none;
    min-height: 300px;
    position: absolute;
    width: 100%;
    background-color: rgba(0,0,0,.8);
    top: 80px;
    left: 0;
    font-size: 0;
    z-index: 999;

    @include rm(320) {
      padding-top: 60px; }
    @include rm(768) {
      padding-top: 20px; } }

  &__submenu-item {
    font-family: $regular;
    box-sizing: border-box;
    transition: .3s;
    @include rm(320) {
      padding-left: 20px; }
    @include rm(768) {
      padding-left: 5px; }

    &:hover {
      background-color: rgba(13,45,81,.8); }
    a {
      color: #d0d1d3; }


    &_title {
      margin-top: 10px;

      a {
        color: #fff; } } }

  &__submenu-close {
    width: 50px;
    height: 50px;
    background-image: url(../img/acordeon__close.png);
    background-size: contain;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    @include rm(768) {
      display: none; } } }
