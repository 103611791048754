.links {
  @include clr;

  &__item {
    font-size: .777em;
    padding-left: 40px;
    margin-top: 20px;
    background-image: url(../img/link.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    box-sizing: border-box;
    min-height: 40px;
    text-decoration: none;

    @include rm(320) {
      lost-column: 1; }
    @include rm(768) {
      lost-column: 1/2; }
    @include rm(1366) {
      lost-column: 1/4; }

    span {
      display: block;
      width: 100%;
      height: 100%;
      color: $darkBlue;
      font-family: $bold; } } }
