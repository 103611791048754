.news {
  padding: 170px 10%;
  @include clr;

  @include rm(768) {
    padding: 170px 0; }
  @include rm(1024) {
    padding: 170px 10%; }

  &__item {
    background-color: #e6e7ea;
    width: 280px;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;
    cursor: pointer;

    &_left {
      float: left; }

    &_right {
      float: right; } }

  &__img {
    height: 55px; }

  &__title {
    font-family: $extraBold;
    font-size: 1.333em;
    margin-top: 25px;
    text-transform: uppercase; } }
