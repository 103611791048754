.section {
  font-size: 1.111em;
  font-family: $regular;
  padding: 90px 0;

  &__text {
    margin: 0; }

  & ul {
    line-height: 1em;

    & li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '●';
        font-size: .6em;
        position: absolute;
        left: 0; } } }

  &__text_border {
    font-family: $bold;
    line-height: 1.1em;
    color: $darkBlue;
    margin: 50px 0;

    @include rm(320) {
      font-size: 1.5em;
      padding-left: 20px;
      border-left: 5px solid $darkBlue; }
    @include rm(768) {
      font-size: 2em;
      padding-left: 35px;
      border-left: 10px solid $darkBlue; } } }
