$linkColor: #0B80E0;
$fontColor: #7A7A8D;
$darkBlue: #0E182D;
$lightBlue: #0d2d51;
$red: #FF6863;
$redHover: #ED3E3E;
$grey: #2A2A2A;
$lightGrey: #F1F1F1;
$black: #000;

@include font(Circe-Bold, Circe-Bold);
@include font(Circe-ExtraBold, Circe-ExtraBold);
@include font(Circe-Regular, Circe-Regular);

$bold: Circe-Bold;
$extraBold: Circe-ExtraBold;
$regular: Circe-Regular;

body {
  font-size: 18px;
  font-family: $regular, sans-serif; }

.container {

  max-width: 1120px;
  margin: 0 auto;
  @include clr;

  @include rm(320) {
    width: 90%; }
  @include rm(768) {
    width: 80%; } }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  color: #0B80E0;
  text-decoration: underline; }
